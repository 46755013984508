import * as THREE from 'three'
import gsap, { scrollTrigger } from 'gsap';
import pillAnimation from './pillAnimation';

export default function App() {
  const body = document.querySelector('body');
  const toggleDN = document.querySelector('#dn');
  const eExperience = document.querySelector('.experience');
  const eHackerMode = document.querySelector('.toggle-hacker-mode');
  const pillSpanTag = document.querySelector('.toggle-hacker-mode span');
  let isMatrixMode = 0;

  const macbookWireframeOn = () => {
    macbook.macbook_base.children[0].children[0].children[0].children[2].material.wireframe = true;
  }

  const defineObjectLighting = () => {
    return {
      uNightMix: experience.world.baked.model.material.uniforms.uNightMix,
      uLightTvStrength: experience.world.baked.model.material.uniforms.uLightTvStrength,
      uLightDeskStrength: experience.world.baked.model.material.uniforms.uLightDeskStrength,
      uLightPcStrength: experience.world.baked.model.material.uniforms.uLightPcStrength,
    }
  }

  const toLight = ({ uNightMix, uLightTvStrength, uLightDeskStrength, uLightPcStrength }) => {
    isMatrixMode = 0;
    body.classList.remove('turn-night');
    body.classList.remove("hacker-mode");
    pillSpanTag.innerText = "Would you want to know the truth?";
    experience.world.macScreen.mesh.material.wireframe = false;
    experience.world.pcScreen.mesh.material.wireframe = false;
    experience.world.baked.model.material.wireframe = false;
    gsap.to(
      experience.world.baked.model.material.uniforms.uNeutralMix,
      {
        duration: 0.3,
        value: 0,
      }
    )
    gsap.to(
      uNightMix,
      {
        duration: 0.3,
        value: 0,
        onComplete: () => {
          gsap.to(uLightTvStrength, { duration: 0.3, value: 0, delay: 0.1 });
          gsap.to(uLightDeskStrength, { duration: 0.3, value: 0, delay: 0.2 });
          gsap.to(uLightPcStrength, { duration: 0.3, value: 0, delay: 0.3 });
        }
      })
  }
  const toDark = ({ uNightMix, uLightTvStrength, uLightDeskStrength, uLightPcStrength }) => {
    isMatrixMode = 0;
    body.classList.add('turn-night');
    pillSpanTag.innerText = "Would you want to know the truth?";
    experience.world.macScreen.mesh.material.wireframe = false;
    experience.world.pcScreen.mesh.material.wireframe = false;
    experience.world.baked.model.material.wireframe = false;

    gsap.to(
      experience.world.baked.model.material.uniforms.uNeutralMix,
      {
        duration: 0.3,
        value: 0,
      }
    )
    experience.world.baked.model.material.uniforms.uLightTvColor.value = new THREE.Color('#ff115e');
    experience.world.baked.model.material.uniforms.uLightDeskColor.value = new THREE.Color('#ff6700');
    experience.world.baked.model.material.uniforms.uLightPcColor.value = new THREE.Color('#0082ff');
    gsap.to(
      uNightMix,
      {
        duration: 0.3,
        value: 1,
        onComplete: () => {
          gsap.to(uLightTvStrength, { duration: 0.3, value: 3, delay: 0.1 });
          gsap.to(uLightDeskStrength, { duration: 0.3, value: 3, delay: 0.2 });
          gsap.to(uLightPcStrength, { duration: 0.3, value: 3, delay: 0.3 });
        }
      })
  }
  const toHackerMode = ({ uNightMix, uLightTvStrength, uLightDeskStrength, uLightPcStrength }) => {
    isMatrixMode = 1;
    pillSpanTag.innerText = "Get me out of here!";
    experience.world.macScreen.mesh.material.wireframe = true;
    experience.world.pcScreen.mesh.material.wireframe = true;
    experience.world.baked.model.material.wireframe = true;

    macbookWireframeOn();

    gsap.to(
      experience.world.baked.model.material.uniforms.uNeutralMix,
      {
        duration: 0.3,
        value: 1,
        onComplete: () => {
          gsap.to(uLightTvStrength, { duration: 0.3, value: 0, delay: 0.1 });
          gsap.to(uLightDeskStrength, { duration: 0.3, value: 0, delay: 0.2 });
          gsap.to(uLightPcStrength, {
            duration: 0.3, value: 0, delay: 0.3, onComplete: () => {
              experience.world.baked.model.material.uniforms.uLightTvColor.value = new THREE.Color('#11CB81');
              experience.world.baked.model.material.uniforms.uLightDeskColor.value = new THREE.Color('#11CB81');
              experience.world.baked.model.material.uniforms.uLightPcColor.value = new THREE.Color('#11CB81');
              gsap.to(uLightTvStrength, { duration: 0.3, value: 3, delay: 0.1 });
              gsap.to(uLightDeskStrength, { duration: 0.3, value: 3, delay: 0.2 });
              gsap.to(uLightPcStrength, { duration: 0.3, value: 3, delay: 0.3 })
            }
          });
        }
      }
    )
  }

  const date = new Date();
  const hours = date.getHours();

  toggleDN.addEventListener('change', function () {
    if (body.classList.contains('turn-night')) toLight(defineObjectLighting());
    else toDark(defineObjectLighting());
  })

  let i = 0;
  const changeBGInterval = setInterval(() => {
    i++;
    if (i > 2) clearInterval(changeBGInterval);
    else eExperience.classList.add(`room_0${i}`);
  }, 800)

  const textWelcomeByTime = () => {
    if (hours > 12) document.querySelector('#text-time').innerText = 'good afternoon';
    if (hours > 16) document.querySelector('#text-time').innerText = 'have a nice evening';
  }

  const roomLightByTime = () => {
    if (6 > hours || hours > 16) {
      const event = document.createEvent("HTMLEvents");
      event.initEvent('change', false, true);
      toggleDN.dispatchEvent(event);
      toggleDN.checked = true;
    }
  }

  const animationAfterLoaded = () => {
    setTimeout(function () {
      const mySignature = document.querySelector('#my_sign')
      mySignature.classList.add("animate");
    }, 2800)
    clearInterval(changeBGInterval);
    eExperience.classList.add("clear");
    eExperience.classList.remove("room_01");
    eExperience.classList.remove("room_02");
    body.classList.add("loaded")
  }

  const handleParameters = () => {
    const url = new URL(window.location);
    const isDarkmode = url.searchParams.get("darkmode") || window.innerWidth < 768;
    if (isDarkmode === '1') {
      toggleDN.checked = true;
      toDark(defineObjectLighting());
    }
  }

  const handleClickHackerMode = (e) => {
    e.preventDefault();
    body.classList.toggle("hacker-mode");
    if (body.classList.contains('hacker-mode')) toHackerMode(defineObjectLighting());
    else toDark(defineObjectLighting());
  }
  eHackerMode.addEventListener('click', handleClickHackerMode)

  // FORMS
  const inputs = document.querySelectorAll('.mdc-text-field__input');
  inputs.forEach(input => {
    input.addEventListener('change', function () {
      if (this.value) this.classList.add('has-value')
      else this.classList.remove('has-value')
    })
  });

  // SCROLL
  const degrees_to_radians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }
  let previousScroll = 0;
  window.addEventListener('scroll', (e) => {
    const windowScrolltop = document.documentElement.scrollTop - previousScroll;
    const radians = degrees_to_radians(windowScrolltop);
    experience.navigation.view.drag.delta.x = -radians * 30;
    experience.navigation.view.drag.delta.y = -radians * 20;
    previousScroll = document.documentElement.scrollTop;
  })

  const handleEventModelLoad = () => {
    textWelcomeByTime();
    roomLightByTime();
    animationAfterLoaded();
    handleParameters();
    pillAnimation(isMatrixMode);
  }
  window.addEventListener('model-loaded', handleEventModelLoad)
}