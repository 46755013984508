import * as THREE from 'three'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default class iPhone {
  constructor(_options = {}) {
    this.targetElement = _options.targetElement;
    if (!this.targetElement) {
      console.warn('Missing \'targetElement\' property')
      return
    }
    const dimension = {
      x: window.innerWidth,
      y: window.innerWidth,
    }
    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0x333333);

    // Camera
    const camera = new THREE.PerspectiveCamera(70, dimension.x / dimension.y, 0.1, 1000);
    camera.position.z = -1;

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      alpha: true
    });
    renderer.setSize(dimension.x, dimension.y);
    renderer.colorManagement = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    this.targetElement.appendChild(renderer.domElement)

    // Lighting
    const hlight = new THREE.AmbientLight(0xffffff, 3.5);
    scene.add(hlight);

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.minPolarAngle = Math.PI / 2;
    controls.maxPolarAngle = Math.PI / 2;

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    const group = new THREE.Group();
    const rgbeLoader = new RGBELoader();
    rgbeLoader.load('../../assets/spruit_dawn_2k.hdr', function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      // scene.background = texture;
      scene.environment = texture;

      const loader = new GLTFLoader();
      loader.load('../assets/iphone/scene.gltf', function (gltf) {
        const iPhone = gltf.scene;
        group.add(iPhone);

        loader.load('../assets/iphone/display.glb', function (gltf) {
          const display = gltf.scene;
          // Screen
          const video = document.createElement('video')
          video.src = '../assets/proshake_screen.mp4'
          video.loop = true;
          video.muted = true;
          video.play();
          // Screen Texture
          const screenTexture = new THREE.VideoTexture(video)
          screenTexture.encoding = THREE.sRGBEncoding
          screenTexture.minFilter = THREE.LinearFilter;
          screenTexture.magFilter = THREE.LinearFilter;
          screenTexture.format = THREE.RGBFormat;
          // Screen Material
          const screenMaterial = new THREE.MeshBasicMaterial({
            map: screenTexture,
            side: THREE.DoubleSide
          })
          display.traverse((obj) => {
            if (obj instanceof THREE.Mesh) {
              obj.material = screenMaterial;
            }
          });
          display.position.z = -0.0001;
          group.add(display);
          scene.add(group);

          animate();

          gsap.registerPlugin(ScrollTrigger);
          gsap.fromTo(
            group.rotation,
            {
              y: 0,
            },
            {
              y: -Math.PI * 0.2,
              scrollTrigger: {
                trigger: document.querySelector(".frame_iPhone"),
                start: "30% 50%",
                end: "60% 50%",
                scrub: 2,
                // markers: true
              }
            }
          )
        })
      })
    })
  }
}