import './style.css'
import Experience from './Experience/Experience.js'
import iPhone from './App/iPhone.js'
import macbook from './App/macbook.js'
import MyApp from './App/App.js'

window.onload = function () {
  window.experience = new Experience({
    targetElement: document.querySelector('.experience')
  });

  window.iPhone = new iPhone({
    targetElement: document.querySelector('.frame_iPhone')
  });

  window.macbook = new macbook({
    targetElement: document.querySelector('.frame_macbook')
  });

  window.myapp = new MyApp();
}