export default [
    {
        name: 'base',
        data: {},
        items:
            [
                { name: 'topChairModel', source: '/assets/topChairModel.glb', type: 'model' },
                { name: 'coffeeSteamModel', source: '/assets/coffeeSteamModel.glb', type: 'model' },
                { name: 'josephtranLogoTexture', source: '/assets/josephtranLogo.png', type: 'texture' },
                { name: 'pcScreenModel', source: '/assets/pcScreenModel.glb', type: 'model' },
                { name: 'macScreenModel', source: '/assets/macScreenModel.glb', type: 'model' },
                { name: 'bakedDayTexture', source: '/assets/bakedDay.jpg', type: 'texture' },
                { name: 'bakedNightTexture', source: '/assets/bakedNight.jpg', type: 'texture' },
                { name: 'lightMapTexture', source: '/assets/lightMap.jpg', type: 'texture' },
                { name: 'roomModel', source: '/assets/room_2_cars_8.glb' },
            ]
    }
]