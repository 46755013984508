import * as THREE from 'three'

import Experience from './Experience.js'
import gsap from 'gsap'

export default class Car {
  constructor() {
    this.experience = new Experience()
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.scene = this.experience.scene
    this.time = this.experience.time

    // Debug
    if (this.debug) {
      this.debugFolder = this.debug.addFolder({
        title: 'car',
        expanded: true
      })
    }

    this.setModel()
  }

  setModel() {
    this.model = {}
    this.model.mesh = this.resources.items.carModel.scene.children[0]
    this.model.mesh.scale.set(0.1, 0.1, 0.1)
    this.model.mesh.position.set(0.9, 1, 1.15)
    this.model.mesh.rotation.y = -0.5
    this.scene.add(this.model.mesh)
  }
}