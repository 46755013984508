import * as THREE from 'three'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default class macbook {
  constructor(_options = {}) {
    const _this = this;
    _this.targetElement = _options.targetElement;
    if (!_this.targetElement) {
      console.warn('Missing \'targetElement\' property')
      return
    }

    const dimension = {
      x: window.innerWidth,
      y: window.innerWidth,
    }
    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0x333333);

    // Camera
    const camera = new THREE.PerspectiveCamera(50, dimension.x / dimension.y, 0.1, 1000);
    camera.position.y = 2;
    camera.position.z = 4;

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      alpha: true
    });
    renderer.setSize(dimension.x, dimension.y);
    renderer.colorManagement = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    this.targetElement.appendChild(renderer.domElement)

    // Lighting
    const hlight = new THREE.AmbientLight(0xffffff, 3.5);
    scene.add(hlight);

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;
    // controls.minPolarAngle = Math.PI / 2;
    // controls.maxPolarAngle = Math.PI / 2;

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    const group = new THREE.Group();
    const groupDisplay = new THREE.Group();

    const loader = new GLTFLoader();
    loader.load('../assets/macbook/macbook_base.glb', function (gltf) {
      _this.macbook_base = gltf.scene;
      group.add(_this.macbook_base);

      loader.load('../assets/macbook/macbook_screen.glb', function (gltf_screen) {
        const macbook_screen = gltf_screen.scene

        // Loading LCD display
        loader.load('../assets/macbook/macbook_display.glb', function (gltf_display) {
          const macbook_display = gltf_display.scene;

          // Screen
          const video = document.createElement('video');
          video.src = './assets/display_screen.mp4'
          video.loop = true;
          video.muted = true;
          video.play();
          // Screen texture
          const screenTexture = new THREE.VideoTexture(video);
          screenTexture.encoding = THREE.sRGBEncoding;
          screenTexture.minFilter = THREE.LinearFilter;
          screenTexture.magFilter = THREE.LinearFilter;
          screenTexture.format = THREE.RGBFormat;
          screenTexture.flipY = false;
          // Screen material
          const screenMaterial = new THREE.MeshBasicMaterial({
            map: screenTexture,
            side: THREE.DoubleSide,
          })
          macbook_display.traverse((obj) => {
            if (obj instanceof THREE.Mesh) {
              obj.material = screenMaterial;
            }
          })

          macbook_screen.applyMatrix4(new THREE.Matrix4().makeTranslation(1.39, 0.06, 1.225));
          macbook_display.applyMatrix4(new THREE.Matrix4().makeTranslation(1.39, 0.06, 1.225));
          groupDisplay.add(macbook_screen);
          groupDisplay.add(macbook_display);
          groupDisplay.rotation.x = Math.PI / 2;
          groupDisplay.position.set(-1.39, -0.06, -1.225);

          group.add(groupDisplay);
          group.position.set(0, -0.8, 0);
          group.scale.set(0.7, 0.7, 0.7);
          scene.add(group);

          gsap.registerPlugin(ScrollTrigger);
          gsap.fromTo(
            groupDisplay.rotation,
            { x: Math.PI / 2 },
            {
              x: -Math.PI / 10,
              ease: "power2.out",
              scrollTrigger: {
                trigger: document.querySelector(".frame_macbook"),
                start: "20% 50%",
                end: "50% 50%",
                scrub: 2,
                // markers: true
              }
            }
          )
          gsap.fromTo(
            group.rotation,
            { y: 0 },
            {
              y: Math.PI * 0.2,
              ease: "power2.out",
              scrollTrigger: {
                trigger: document.querySelector(".frame_macbook"),
                start: "20% 50%",
                end: "50% 50%",
                scrub: 2,
                // markers: true
              }
            }
          )

          animate();
        })
      })
    })
  }
}