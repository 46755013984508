import * as THREE from 'three'
import Experience from './Experience.js'
import Baked from './Baked.js'
import Car from './Car.js'
import CoffeeSteam from './CoffeeSteam.js'
import TopChair from './TopChair.js'
import BouncingLogo from './BouncingLogo.js'
import Screen from './Screen.js'

export default class World {
  constructor(_options) {
    this.experience = new Experience()
    this.config = this.experience.config
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.resources.on('groupEnd', (_group) => {
      if (_group.name === 'base') {
        this.setBaked()
        // this.setCar()
        // this.setCoffeeSteam()
        this.setTopChair()
        this.setBouncingLogo()
        if (localStorage.getItem('screen') && localStorage.getItem('screen') == 2) {
          this.setScreens()
          localStorage.setItem('screen', 1)
        } else {
          this.setHackerScreens()
          localStorage.setItem('screen', 2)
        }
        window.dispatchEvent(new CustomEvent('model-loaded'));
      }
    })
  }

  setBaked() {
    this.baked = new Baked()
  }

  setCar() {
    this.car = new Car()
  }

  setCoffeeSteam() {
    this.coffeeSteam = new CoffeeSteam()
  }

  setTopChair() {
    this.topChair = new TopChair()
  }

  setBouncingLogo() {
    this.bouncingLogo = new BouncingLogo()
  }

  setScreens() {
    this.pcScreen = new Screen(
      this.resources.items.pcScreenModel.scene.children[0],
      '/assets/monitor_screen.mp4'
    )
    this.macScreen = new Screen(
      this.resources.items.macScreenModel.scene.children[0],
      '/assets/laptop_screen.mp4'
    )
  }

  setHackerScreens() {
    this.pcScreen = new Screen(
      this.resources.items.pcScreenModel.scene.children[0],
      '/assets/hacker-mode-1.mp4'
    )
    this.macScreen = new Screen(
      this.resources.items.macScreenModel.scene.children[0],
      '/assets/hacker-mode-2.mp4'
    )
  }

  resize() {
  }

  update() {
    if (this.coffeeSteam)
      this.coffeeSteam.update()

    if (this.topChair)
      this.topChair.update()

    if (this.bouncingLogo)
      this.bouncingLogo.update()
  }

  destroy() {
  }
}