const pillAnimation = (isMatrixMode) => {
  const pillImgTag = document.querySelector('.toggle-hacker-mode .img');
  let i = 1;
  const fpsLimit = 24;
  let
    currentDelta = 0,
    previousDelta = 0,
    updateId;
  const changeSrcPillImage = (currentDelta) => {
    updateId = requestAnimationFrame(changeSrcPillImage);
    var delta = currentDelta - previousDelta;
    if (fpsLimit && delta < 1000 / fpsLimit) {
      return;
    }

    previousDelta = currentDelta;
    if (i < 38) i++;
    else i = 0;
    pillImgTag.style.backgroundPosition = (i * -32) + 'px center';
    // const str = "" + i;
    // const pad = "0000"
    // const ans = (isMatrixMode ? 'blue/' : 'red/') + pad.substring(0, pad.length - str.length) + str
    // pillImgTag.src = `https://trantuananh.info/assets/pill/${ans}.png`;
  }
  changeSrcPillImage(currentDelta);
}
export default pillAnimation;